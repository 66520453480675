export type TEXT_FILTER_OPERATION = 'equals' | 'notEqual' | 'blank' | 'notBlank' | 'empty' | 'contains' | 'notContains' | 'startsWith' | 'endsWith'

export type NUMBER_FILTER_OPERATION =
  | 'equals'
  | 'notEqual'
  | 'lessThan'
  | 'lessThanOrEqual'
  | 'greaterThan'
  | 'greaterThanOrEqual'
  | 'inRange'
  | 'blank'
  | 'notBlank'
  | 'empty'

export type DATE_FILTER_OPERATION =
  | 'equals'
  | 'notEqual'
  | 'lessThan'
  | 'lessThanOrEqual'
  | 'greaterThan'
  | 'greaterThanOrEqual'
  | 'inRange'
  | 'blank'
  | 'notBlank'
  | 'empty'

type OPERATION = DATE_FILTER_OPERATION | TEXT_FILTER_OPERATION | NUMBER_FILTER_OPERATION

type FilterValues = string | number | [number, number] | Date | [Date, Date]

type Filter<O = OPERATION, V = FilterValues> = {
  field: string
  operation: O
  value?: V
}

export class FilterQueryParams {
  query: string
  constructor() {
    this.query = ''
  }
  and(...args: string[]) {
    return args.filter(item => item !== undefined).join('&')
  }
  or(...args: string[]) {
    return args.filter(item => item !== undefined).join('|')
  }
  group(arg: string) {
    return `(${arg})`
  }

  textFilter(filter: Filter<TEXT_FILTER_OPERATION, string>) {
    const { field, operation, value } = filter

    switch (operation) {
      case 'contains':
        return `${field}:contains(${value})`
      case 'notContains':
        return `!(${field}:contains(${value}))`
      case 'equals':
        return `${field}:eq(${value})`
      case 'notEqual':
        return `${field}:ne(${value})`
      case 'startsWith':
        return `${field}:startsWith(${value})`
      case 'endsWith':
        return `${field}:endsWith(${value})`
      case 'blank':
        return `${field}:null()`
      case 'notBlank':
        return `!(${field}:null())`
      case 'empty':
        return `${field}:null()`
      default:
        throw new Error(`Unsupported operation: ${operation}`)
    }
  }

  numberFilter(filter: Filter<NUMBER_FILTER_OPERATION, number | [number, number]>) {
    const { field, operation, value } = filter

    // Edge cases ---------------/

    if (Array.isArray(value) && operation === 'inRange') {
      const fromValue = value[0]
      const toValue = value[1]

      if (fromValue && toValue) {
        return `${field}:gte(${fromValue})&${field}:lte(${toValue})`
      } else {
        throw new Error("Both from and to values must be provided for an 'inRange' operation.")
      }
    }

    // Normal cases ---------------/

    switch (operation) {
      case 'equals':
        return `${field}:eq(${value})`
      case 'notEqual':
        return `${field}:ne(${value})`
      case 'blank':
        return `${field}:null()`
      case 'notBlank':
        return `!(${field}:null())`
      case 'empty':
        return `${field}:null()`
      case 'lessThan':
        return `${field}:lt(${value})`
      case 'lessThanOrEqual':
        return `${field}:lte(${value})`
      case 'greaterThan':
        return `${field}:gt(${value})`
      case 'greaterThanOrEqual':
        return `${field}:gte(${value})`
      default:
        throw new Error(`Unsupported operation: ${operation}`)
    }
  }

  dateFilter(filter: Filter<DATE_FILTER_OPERATION, Date | [Date, Date]>) {
    const { value, field, operation } = filter
    // Edge cases ---------------/

    if (Array.isArray(value)) {
      if (operation !== 'inRange') throw new Error('A range of values can only be used with `inRange` operations.')
      let fromValue: any, toValue: any
      fromValue = value[0].toISOString()
      toValue = value[1].toISOString()

      return `${field}:gte(${fromValue})&${field}:lte(${toValue})`
    }

    if (operation === 'equals' || operation === 'notEqual') {
      // Synthesize searching for a "day" by doing gte(00:00:00)&lte(23:59:59), i.e. midnight of that day up to the last second of that day
      const date = extractDatePortion(value.toISOString())
      const dateFrom = `${date}T00:00:00Z` // format ISO 8601
      const dateTo = `${date}T23:59:59:999Z` // format ISO 8601

      // for 'equals', values should be inclusive (gte and lte)
      if (operation === 'equals') return `(${field}:gte(${dateFrom})&${field}:lte(${dateTo}))`
      // for 'notEqual', values should be not-inclusive (lt and gt)
      if (operation === 'notEqual') return `(${field}:lt(${dateFrom})&${field}:gt(${dateTo}))`
    }

    let _value = value.toISOString()

    // Normal cases ---------------/

    switch (operation) {
      // Note: 'contains' operations are not supported by the Engage API for Dates
      case 'lessThan':
        return `${field}:lt(${_value})`
      case 'lessThanOrEqual':
        return `${field}:lte(${_value})`
      case 'greaterThan':
        return `${field}:gt(${_value})`
      case 'greaterThanOrEqual':
        return `${field}:gte(${_value})`
      case 'blank':
        return `${field}:null()`
      case 'notBlank':
        return `!(${field}:null())`
      case 'empty':
        return `${field}:null()`
      case 'inRange':
        throw new Error('`inRange` operations must be used with an array of values.')
      default:
        throw new Error(`Unsupported operation: ${operation}`)
    }
  }

  setFilter(filter:{field: string, values: any[]}) {
    return `${filter.field}:in(${filter.values.join(',')})`
  }
}

function extractDatePortion(date: string) {
  // ISOString format: 'YYYY-MM-DDT00:00:00.00Z'
  const match = date.split(' ')
  return match ? match[0] : null
}

// ! EXAMPLE only

// const q = new FilterQueryParams()

// const firstName = q.textFilter({
//   field: 'firstName',
//   operation: 'contains',
//   value: 'Dan',
// })
// const lastNameContainsV = q.textFilter({
//   field: 'lastName',
//   operation: 'contains',
//   value: 'V',
// })
// const lastNameContainsF = q.textFilter({
//   field: 'lastName',
//   operation: 'contains',
//   value: 'F',
// })
// const isAfterDecember2022 = q.dateFilter({
//   field: 'nextFollowUp',
//   operation: 'greaterThan',
//   value: new Date('12-01-2022'),
// })
// const filterQuery = q.and(firstName, q.group(q.or(lastNameContainsV, lastNameContainsF)), isAfterDecember2022)

// console.log(filterQuery) // returns => firstName:contains(Dan)&(lastName:contains(V)|lastName:contains(F))
