import { ENTITY } from '@app/types'

import { ACTIVITY_ENTITIES } from './fieldOptions'

type GeoRequest = {
  line1: string
  city: string
  state: string
  country: string
  postalCode: string
}

type CreditAppRequest = {
customerNumber: string
userId: string
customerName: string
customerEmail: string
}

export const CREDIT_APP = ({customerNumber, userId, customerName, customerEmail}: CreditAppRequest) => `/v1/creditapp?cust_no=${customerNumber}&user_id=${userId}&cust_name=${customerName}&cust_email=${customerEmail}`
export const GEOCODER = ({ line1, city, state, country, postalCode }: GeoRequest) =>
  `/geo/geocode?line1=${line1}&city=${city}&state=${state}&country=${country}&postalCode=${postalCode}`

export const LEADS = '/engage/leads'
export const LEADS_OPTIONS = {
  STATUSES: '/engage/leads/statuses',
  QUALITY_RATINGS: '/engage/leads/quality-ratings',
}

export const LEADS_CARD = '/engage/leads/scan-business-card'
export const LEADS_NOTES = (leadId: string) => `/engage/leads/${leadId}/notes`
export const LEADS_NOTE = (leadId: string, noteId: string) => `/engage/leads/${leadId}/notes/${noteId}`
export const CONVERT_LEAD = (leadId: string) => `/engage/leads/${leadId}/convert`

export const ACCOUNTS = '/engage/accounts'
export const ACCOUNTS_OPTIONS = {
  MANAGEMENT_TYPES: '/engage/accounts/management-types',
  STATEMENT_PREFERENCES: '/engage/accounts/statement-preferences',
  CREDIT_CARD_SURCHARGE_TYPES: '/engage/accounts/credit-card-surcharge-types',
  ORDER_DISPOSITIONS: '/engage/accounts/order-dispositions',
  ADDON_TYPES: '/engage/accounts/addon-types',
}
export const ACCOUNTS_ENUMS = '/engage/accounts/enums'
export const ACCOUNTS_NOTES = (accountId: string) => `/engage/accounts/${accountId}/notes`
export const ACCOUNTS_NOTE = (accountId: string, noteId: string) => `/engage/accounts/${accountId}/notes/${noteId}`

export const CONTACTS = '/engage/contacts'
export const CONTACTS_NOTES = (contactId: string) => `/engage/contacts/${contactId}/notes`
export const CONTACTS_NOTE = (contactId: string, noteId: string) => `/engage/contacts/${contactId}/notes/${noteId}`
export const CONTACT_TYPES = '/engage/contacts/contact-types'

export const OPPORTUNITIES = '/engage/opportunities'
export const OPPORTUNITIES_ENUMS = '/engage/opportunities/enums'
export const OPPORTUNITIES_NOTES = (opportunityId: string) => `/engage/opportunities/${opportunityId}/notes`
export const OPPORTUNITIES_NOTE = (opportunityId: string, noteId: string) => `/engage/opportunities/${opportunityId}/notes/${noteId}`
export const OPPORTUNITIES_BY_CONTACT = (contactId: string) => `/engage/opportunities?filter=primaryStakeholder.id:eq(${contactId})`
export const OPPORTUNITIES_BY_ACCOUNT = (accountId: string) => `/engage/opportunities?filter=account.id:eq(${accountId})`

export const SITES = '/engage/sites'
export const SITES_BY_ACCOUNT = (accountId: string) => `/engage/sites?filter=owner:eq(${accountId})` //if this for some reason doesn't fetch, we can update the param to 'owner.id:eq . . .'
export const SITES_NOTES = (siteId: string) => `/engage/sites/${siteId}/notes`
export const SITES_NOTE = (siteId: string, noteId: string) => `/engage/sites/${siteId}/notes/${noteId}`
export const SITE_TYPES = '/engage/sites/site-types'

export const PAYMENT_METHODS = '/engage/payment-methods'
export const BILLING_GROUPS = '/engage/billing-groups'
export const MARKETING_GROUPS = '/engage/marketing-groups'
export const JOB_FUNCTIONS = '/engage/job-functions'
export const SERVICES = '/engage/services'
export const SOURCES = '/engage/sources'
export const INDUSTRIES = '/engage/industries'
export const TIME_FRAMES = '/engage/time-frames'

export const GEO = {
  COUNTRIES: '/engage/geo/countries',
  STATES: (countryCode: string) => `/engage/geo/countries/${countryCode}/states`,
}
export const ACTIVITIES = (entity: ENTITY | string, id: string) => `/engage/${entity}/${id}/activities?limit=5000`
export const ACTIVITY_NOTE = (activityId: string) => `/engage/activities/${activityId}/notes`
export const ROOT_ACTIVITIES = '/engage/activities'

export const CAMPAIGNS = '/engage/campaigns'
export const CAMPAIGN = (id: string) => `/engage/campaigns/${id}`
export const CAMPAIGN_EXPENSES = (id: string) => `/engage/campaigns/${id}/expenses`
export const CAMPAIGN_EXPENSE = (campaignId: string, expenseId: string) => `/engage/campaigns/${campaignId}/expenses/${expenseId}`
export const CAMPAIGNS_COST_CODES = '/engage/campaigns/cost-codes'
export const CAMPAIGNS_ENUMS = '/engage/campaigns/enums'

export const DIVISIONS = '/v1/divisions'

export const REPORTS = {
  SALES_ACTIVITY: (contactId: string) => `/engage/reports/sales-activity/${contactId}`,
  MARKETING_SUMMARY: '/engage/reports/marketing-summary',
}

export const MULTI_SEARCH = '/engage/search'
export const ENTITY_SEARCH = (entity: ENTITY, input: string) => `/engage/${entity}/search?q=${input}`

export const QUICKSIGHT = '/quicksight/link'

export const FILE_STORAGE = {
  DIRECTORY: '/file-storage/directories',
  DIRECTORIES: (path: string) => `/file-storage/directories?path=${path}`,
  FILE: '/file-storage/files',
  FILES: (path: string) => `/file-storage/files?path=${path}`,
  MOVE_FILE: '/file-storage/files/move',
  COPY_FILE: '/file-storage/files/copy',
  DOWNLOAD: (id: string) => `/file-storage/files/${id}`,
}

export const ENDPOINTS = {
  GEOCODER,
  CREDIT_APP,
  LEADS,
  LEADS_OPTIONS,
  LEADS_CARD,
  LEADS_NOTES,
  LEADS_NOTE,
  CONVERT_LEAD,
  ACCOUNTS,
  ACCOUNTS_ENUMS,
  ACCOUNTS_OPTIONS,
  ACCOUNTS_NOTES,
  ACCOUNTS_NOTE,
  CONTACTS,
  CONTACTS_NOTES,
  CONTACTS_NOTE,
  CONTACT_TYPES,
  OPPORTUNITIES,
  OPPORTUNITIES_ENUMS,
  OPPORTUNITIES_NOTES,
  OPPORTUNITIES_NOTE,
  GEO,
  PAYMENT_METHODS,
  BILLING_GROUPS,
  MARKETING_GROUPS,
  JOB_FUNCTIONS,
  SERVICES,
  SOURCES,
  INDUSTRIES,
  TIME_FRAMES,
  OPPORTUNITIES_BY_CONTACT,
  OPPORTUNITIES_BY_ACCOUNT,
  ROOT_ACTIVITIES,
  ACTIVITIES,
  ACTIVITY_ENTITIES,
  SITES,
  SITES_BY_ACCOUNT,
  SITES_NOTES,
  SITES_NOTE,
  SITE_TYPES,
  CAMPAIGNS,
  CAMPAIGN,
  CAMPAIGN_EXPENSES,
  CAMPAIGN_EXPENSE,
  CAMPAIGNS_COST_CODES,
  CAMPAIGNS_ENUMS,
  DIVISIONS,
  REPORTS,
  MULTI_SEARCH,
  ENTITY_SEARCH,
  QUICKSIGHT,
  FILE_STORAGE,
}
